import storage from 'store';
import { login, getUserInfo } from '@/api';

export default {
	LOGIN: function ({ commit }, userData) {
		return new Promise((resolve, reject) => {
			login(userData)
				.then((res) => {
					storage.set('crawler_dashboard_token', res.token);
					commit('setToken', res.token);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	FETCH_USER_INFO: function ({ commit }) {
		return new Promise((resolve, reject) => {
			getUserInfo()
				.then((response) => {
					let data = response.data;

					commit('setAccountID', data.sn);
					commit('setAccount', data.account);
					commit('setAccountName', data.fullName);
					commit('setCurrentPlan', data.currentPlan);
					commit('setCurrentPlanPeriod', data.currentPlanPeriod);
					commit('setNextPlan', data.nextPlan);
					commit('setNextPlanPeriod', data.nextPlanPeriod);
					commit('setNextPaymentDate', data.nextPaymentDate);
					commit('setLastfour', data.cardLastfour);
					commit('setCurrentPlanStartDate', data.currentPlanStartDate);
					commit('setCreditTotal', data.creditTotal);
					commit('setInvoice', data.receipt);

					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	Logout: function ({ commit }) {
		return new Promise((resolve) => {
			commit('setToken', '');
			commit('setAccountID', '');
			commit('setAccount', '');
			storage.remove('crawler_dashboard_token');
			resolve();
		});
	},
};
