<template>
	<div class="login">
		<div class="login-overlay" />
		<div class="login-content">
			<div class="login-main">
				<div class="login-top">
					<img src="@/assets/images/logo.svg" alt="Growise" />
					<h1>{{ $t('Login.Title') }}</h1>
				</div>
				<div class="form-group">
					<input
						type="text"
						class="form-control form-large"
						:placeholder="$t('Login.EmailPlaceholder')"
						v-model="email"
					/>
				</div>
				<div class="form-group">
					<input
						type="password"
						class="form-control form-large"
						:placeholder="$t('Login.PasswordPlaceholder')"
						v-model="password"
					/>
				</div>
				<div class="agree-text">
					<i18n path="Login.Authorization" tag="p">
						<template v-slot:Terms>
							<a href="https://www.test-web.growise.io/Terms" target="_blank">{{
								$t('Login.Terms')
							}}</a>
						</template>
						<template v-slot:PrivacyPolicy>
							<a href="https://www.test-web.growise.io/Privacy" target="_blank">{{
								$t('Login.PrivacyPolicy')
							}}</a>
						</template>
					</i18n>
				</div>
				<div class="form-group">
					<button class="button button-block button-large" @click="doLogin">
						{{ $t('Login.LoginButton') }}
					</button>
				</div>
				<div class="tip-text">
					{{ $t('Login.Unregistered') }}
					<a href="https://www.test-web.growise.io/Apply" target="_blank">{{
						$t('Login.SignUp')
					}}</a>
				</div>
				<div class="tip-text">
					<a href="/auth/forgot">{{ $t('Login.ForgotPassword') }}</a>
					or
					<a href="/auth/verify">{{ $t('Login.ResendActiveEmail') }}</a>
				</div>
				<!-- <div class="tip-text"><a href="">Forgot password?</a></div> -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Login',
	data() {
		return {
			email: '',
			password: '',
		};
	},
	methods: {
		doLogin() {
			let { email, password } = this;
			if (email == '' || password == '') {
				alert('請填寫登入資訊');
				return;
			}
			this.$store
				.dispatch('LOGIN', { email, password })
				.then(() => {
					this.$router.push('/');
				})
				.catch((error) => {
					if (
						error.response &&
						error.response.data &&
						error.response.data.message
					) {
						alert(error.response.data.message);
					} else {
						alert('認證失敗');
					}
				});
		},
	},
};
</script>

<style scoped></style>
