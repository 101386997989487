<template>
	<div class="account page">
		<h1 class="page-title">{{ $t('UserAccount.ChangeName') }}</h1>
		<div class="form-block">
			<div class="form-col">
				<div class="form-group">
					<label for="name" class="form-label">{{
						$t('UserInfo.NickName')
					}}</label>
					<input
						type="text"
						class="form-control"
						name="name"
						v-model="nickName"
					/>
				</div>
			</div>
			<div class="button-group">
				<button class="button">{{ $t('UserInfo.Save') }}</button>
				<router-link
					:to="{ path: '/userAccount' }"
					class="button button-cancel"
				>
					{{ $t('UserInfo.Cancel') }}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ChangeName',
	data() {
		return {
			nickName: '',
		};
	},
	mounted() {
		this.nickName = this.$store.state.accountName;
	},
};
</script>
