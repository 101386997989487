<template>
	<div class="navigation">
		<ul class="navigation-link">
			<li
				:class="{ active: $route.path == data.to }"
				v-for="(data, key) in menu"
				:key="key"
			>
				<router-link :to="data.to" @click.native="toggleNavigation">
					<i class="material-icons-outlined"> {{ data.icon_name }} </i>
					{{ data.name }}
				</router-link>
			</li>
		</ul>
		<div class="change-language">
			<b-button @click="setLanguage('zh')">中文</b-button>
			<b-button @click="setLanguage('en')">English</b-button>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Navigation',
	props: {},
	mounted() {
		this.resetMenu();
	},
	computed: {
		...mapState(['currentPlan']),
	},
	data() {
		return {
			menu: [
				{
					icon_name: 'home',
					name: this.$i18n.t('Navigation.Home'),
					to: '/home',
				},
				{
					icon_name: 'manage_accounts',
					name: this.$i18n.t('Navigation.UserAccount'),
					to: '/userAccount',
				},
				{
					icon_name: 'tag',
					name: this.$i18n.t('Navigation.PlanSubscribe'),
					to: this.currentPlan ? '/replacementPlan' : '/subscribe',
				},
				{
					icon_name: 'credit_card',
					name: this.$i18n.t('Navigation.PaymentMethod'),
					to: '/changeCredit',
				},
				{
					icon_name: 'list',
					name: this.$i18n.t('Navigation.PaymentHistory'),
					to: '/chargeDetails',
				},
			],
		};
	},
	methods: {
		resetMenu() {
			this.menu = [
				{
					icon_name: 'home',
					name: this.$i18n.t('Navigation.Home'),
					to: '/home',
				},
				{
					icon_name: 'people',
					name: this.$i18n.t('Navigation.UserAccount'),
					to: '/userAccount',
				},
				{
					icon_name: 'tag',
					name: this.$i18n.t('Navigation.PlanSubscribe'),
					to: this.currentPlan ? '/replacementPlan' : '/subscribe',
				},
				{
					icon_name: 'credit_card',
					name: this.$i18n.t('Navigation.PaymentMethod'),
					to: '/changeCredit',
				},
				{
					icon_name: 'list',
					name: this.$i18n.t('Navigation.PaymentHistory'),
					to: '/chargeDetails',
				},
			];
		},
		toggleNavigation: function () {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth < 900) {
				this.$emit('toggleNavigation', true);
			}
		},
		setLanguage(value) {
			this.$i18n.locale = value;
			this.resetMenu();
			localStorage.setItem('locale', value);
		},
	},
};
</script>

<style lang="less" scoped>
.navigation {
	display: flex;
	flex-direction: column;
}
.change-language {
	margin-top: auto;
	padding: 20px 0;
	display: flex;
	justify-content: center;
	gap: 16px;
	.btn {
		border: none;
		background-color: #000;
		font-size: 14px;
		line-height: 1;
	}
}
</style>
