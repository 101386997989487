import router from './router';
import store from './store';
import storage from 'store';
import jwt_decode from 'jwt-decode';

const loginRoutePath = '/auth/login';
const userAccountRoutePath = '/userAccount';
const defaultRoutePath = '/home';

let token = storage.get('crawler_dashboard_token');
if (token) {
	let decoded = jwt_decode(token);
	if (decoded && decoded.exp && decoded.exp * 1000 >= Date.now()) {
		store.commit('setToken', token);
	} else {
		storage.remove('crawler_dashboard_token');
	}
}

router.beforeEach((to, from, next) => {
	
	// console.log('to=', to.fullPath, '| from=', from.fullPath);
	if (to.meta && to.meta.title) {
		document.title = to.meta.title;
	}
	if (store.state.token) {
		if (to.path === loginRoutePath) {
			// to login page but already authenticated
			next({ path: defaultRoutePath });
		} else {			
			if (store.state.account !== '') {
				next();
			} else {
				store.dispatch('FETCH_USER_INFO').then(() => {
					if ((to.name == 'subscribe' && store.state.currentPlan && store.state.currentPlan != '')
					|| to.name == 'replacementPlan' && !(store.state.currentPlan && store.state.currentPlan != '')) {
						next({ path: userAccountRoutePath });
					} else {
						next();
					}
				});
				// no user info
				// store
				// 	.dispatch('FETCH_USER_INFO')
				// 	.then((userInfo) => {
				// 		const redirect = decodeURIComponent(from.query.redirect || to.path);
				// 		if (to.path === redirect) {
				// 			// set the replace: true so the navigation will not leave a history record
				// 			next({ ...to, replace: true });
				// 		} else {
				// 			// redirect after login
				// 			next({ path: redirect });
				// 		}
				// 	})
				// 	.catch((error) => {
				// 		// fetch user info failed
				// 		alert('Get user information failed');
				// 		store.dispatch('Logout').then(() => {
				// 			next({ path: loginRoutePath, query: { redirect: to.fullPath } });
				// 		});
				// 	});
			}
		}
	} else {
		if (to.meta && to.meta.auth) {
			// redirect to login page
			next({
				path: loginRoutePath,
				query: {
					redirect: to.fullPath,
				},
			});
		} else {
			next();
		}
	}
});
