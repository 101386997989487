<template>
	<div class="account page">
		<h1 class="page-title">{{ $t('UserAccount.Account') }}</h1>
		<div class="form-block">
			<!-- <div class="info-message">The plan change is completed, and your plan will be replaced on 8/9 2021. </div> -->
			<div class="form-col">
				<div class="form-group">
					<h3 class="sub-title" style="margin-top: 0">
						{{ $t('UserAccount.MemberInfoTitle') }}
					</h3>
				</div>
			</div>
			<div class="form-col">
				<div class="form-group">{{ account }}</div>
				<div class="form-group link-edit">
					<a href="#" class="link" @click="resendResetPassword">{{
						$t('UserAccount.ResetPassword')
					}}</a>
				</div>
				<!-- {{ $t('UserAccount.ChangeEmail') }} -->
				<!-- <div class="form-group link-edit">
					<router-link :to="{ path: '/changeEmail' }">
						{{ $t('UserAccount.ChangeEmail') }}
					</router-link>
				</div> -->
			</div>
			<div class="form-col">
				<div class="form-group">{{ accountName }}</div>
				<div class="form-group link-edit">
					<router-link :to="{ path: '/changeName' }">
						{{ $t('UserAccount.ChangeName') }}
					</router-link>
				</div>
			</div>

			<h3 class="sub-title">{{ $t('UserAccount.ProgramContentTitle') }}</h3>
			<div class="quotas-box">
				<h4 class="current-quotas">
					<strong>{{ creditUsed }}</strong>
					of {{ creditTotal }}
				</h4>
				<div class="quotas-desc">
					{{ creditPercent }}% {{ $t('UserAccount.PercentCreditUsed') }}
					<router-link :to="{ path: '/quotaDetails' }">{{
						$t('UserAccount.UsageDetails')
					}}</router-link>
				</div>
				<div class="quotas-bar">
					<span v-bind:style="{ width: creditPercent + '%' }" />
				</div>
				<div class="quotas-desc" v-if="currentPlan && currentPlan != ''">
					{{
						$t('UserAccount.CreditResetDate', {
							date: dateFormatter(nextPaymentDate),
						})
					}}
					<a
						href="javascript:void(0)"
						@click="increaseCredit"
						v-if="creditPercent >= 80"
					>
						{{ $t('UserAccount.IncreaseCredit') }}
					</a>
				</div>
			</div>
			<div class="form-col">
				<div class="form-group">
					<span v-if="currentPlan && currentPlan != ''">
						{{ currentPlan }}/{{ planPeriodText }}
					</span>
					<span v-else>
						<!-- {{ $t('UserAccount.FreePlan') }} -->
						{{ $t('UserAccount.FreePlan') }}
					</span>
				</div>
				<div class="form-group link-edit">
					<div v-if="currentPlan && currentPlan != ''">
						<router-link :to="{ path: 'replacementPlan' }">
							{{ $t('UserAccount.ReplacementPlan') }}
						</router-link>

						<a
							v-if="nextPlan && nextPlan != ''"
							href="javascript:void(0)"
							@click="cancelSubscribe"
						>
							{{ $t('UserAccount.CancelSubscribe') }}
						</a>
					</div>
					<div v-else>
						<router-link :to="{ path: 'subscribe' }">
							{{ $t('UserAccount.SubscribeNow') }}
						</router-link>
					</div>
				</div>
			</div>
			<div class="form-col">
				<div class="form-group">
					<span class="card-number" v-if="lastfour && lastfour != ''">
						<img src="@/assets/images/payment/visa.svg" alt="visa" />
						•••• •••• •••• {{ lastfour }}
					</span>
					<span v-else> {{ $t('UserAccount.NotPaymentInformation') }} </span>
				</div>
				<div class="form-group link-edit">
					<router-link :to="{ path: '/changeCredit' }">
						{{ $t('UserAccount.PaymentInfo') }}
					</router-link>
				</div>
			</div>
			<div class="form-col">
				<div
					class="form-group"
					v-if="
						currentPlan &&
						currentPlan != '' &&
						nextPlan &&
						nextPlan != '' &&
						nextPaymentDate &&
						nextPaymentDate != ''
					"
				>
					{{ $t('UserAccount.NextBullingDate') }}
					{{ nextPaymentDate | dateFormat }}.
				</div>
				<div class="form-group link-edit">
					<router-link :to="{ path: '/chargeDetails' }">
						{{ $t('UserAccount.ChargeDetails') }}
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getUsedCredit, apiReset, apiCancel } from '@/api';
import moment from 'moment-timezone';

export default {
	name: 'UserAccount',
	data() {
		return {
			creditUsed: 0,
		};
	},
	computed: {
		...mapState([
			'account',
			'accountName',
			'currentPlan',
			'currentPlanPeriod',
			'nextPlan',
			'nextPlanPeriod',
			'nextPaymentDate',
			'lastfour',
			'creditTotal',
		]),
		...mapGetters(['planPeriodText']),
		creditPercent() {
			return this.creditTotal !== null
				? Math.round((this.creditUsed / this.creditTotal) * 10000) / 100
				: 0;
		},
	},
	mounted() {
		this.getCreditUsed();
	},
	methods: {
		getCreditUsed() {
			getUsedCredit().then((res) => {
				this.creditUsed = res.result.total_used;
			});
		},
		async resendResetPassword() {
			const res = await apiReset({});
			if (res.success) {
				alert(this.$t('UserAccount.AlertPasswordUpdate'));
			} else {
				alert(res.message);
			}
		},
		dateFormatter(date) {
			return moment(date).tz('Asia/Taipei').format('MMMM D, YYYY');
		},
		// show confirm and redirect replacementPlan
		increaseCredit() {
			alert(this.$t('UserAccount.AlertIncreaseCredit'));
			this.$router.push('replacementPlan');
		},
		// cancel subscribe
		async cancelSubscribe() {
			const resConfirm = confirm(this.$t('UserAccount.ConfirmCancelSubscribe'));
			if (resConfirm) {
				const res = await apiCancel({});
				if (res.success) {
					alert(this.$t('UserAccount.AlertSubscribeHadCancel'));
				} else {
					alert(res.message);
				}
				// update user info
				this.$store.dispatch('FETCH_USER_INFO');
			}
		},
	},
};
</script>
