<template>
	<div class="index page">
		<h1 class="page-title">{{ script_title }}</h1>
		<ul class="tab">
			<li
				v-bind:class="{ active: currentTab == 'Overview' }"
				@click="changeTab('Overview')"
			>{{ $t('TaskList.Overview') }}</li>
			<li
				v-bind:class="{ active: currentTab == 'Results' }"
				@click="changeTab('Results')"
			>{{ $t('TaskList.Results') }}</li>
		</ul>
		<div class="tool-bar" v-if="currentTab == 'Results' && listDatas.td.length > 0">
			<div class="left"></div>
			<div class="right">
				<a class="button button-small" href="javascript:void(0)" @click="downloadCSV">
					<i class="material-icons-outlined">file_download</i> CSV
				</a>
			</div>
		</div>
		<div class="table-responsive">
			<table>
				<thead>
					<tr>
						<th
							v-for="(data, key) in listDatas.th"
							:key="key"
							v-bind:class="{ 'text-center': data.center }"
						>{{ $t(data.title) }}</th>
					</tr>
				</thead>
				<tbody v-if="listDatas.td.length > 0">
					<tr v-for="(datas, key) in listDatas.td" :key="key">
						<td
							v-for="(data, key2) in datas"
							:key="key2"
							v-bind:class="{ 'text-center': listDatas.th[key2].center }"
						>
							<span v-if="key2 == 0">
								<router-link
									:to="{
										name: 'Log',
										params: { task_id: data, script_id: script_id },
									}"
								>{{ data }}</router-link>
							</span>
							<div class="tag green" v-if="key2 == 1 && data === 'success'">SUCCESS</div>
							<div class="tag red" v-if="key2 == 1 && data === 'fail'">FAIL</div>
							<div class="tag blue" v-if="key2 == 1 && data === 'active'">ACTIVE</div>
							<i class="material-icons-outlined spin" v-if="key2 == 1 && data == 'running'">sync</i>
							<!-- <span v-else-if="key2 == 2">
                      {{ data }}
							</span>-->
							<span v-if="key2 > 1">{{ data }}</span>
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<td class="text-center" colspan="7">No data available in table</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { genResult } from '@/utils/util';
import { getCSV, getScript, getTasks, getScriptResults } from '@/api';
export default {
	name: 'Task',
	props: ['script_id'],
	data() {
		return {
			currentTab: 'Overview',
			listDatas: { th: [], td: [] },
			script_title: '',
		};
	},
	watch: {
		currentTab(val) {
			this.getListDatas(val);
		},
	},
	mounted() {
		this.getScriptData();
		this.getListDatas(this.currentTab);
	},
	methods: {
		downloadCSV() {
			getCSV({ script_id: this.script_id }).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'file.csv');
				document.body.appendChild(link);
				link.click();
			});
		},
		changeTab(type) {
			this.currentTab = type;
		},
		getScriptData() {
			getScript(this.script_id).then((res) => {
				this.script_title = res.title;
			});
		},
		getListDatas(type) {
			if (type == 'Results') {
				this.listDatas = { th: [], td: [] };
				getScriptResults(this.script_id).then((res) => {
					this.listDatas = genResult(res.rows);
				});
			} else {
				this.listDatas = {
					th: [
						{ title: 'TaskList.TaskID', center: false },
						{ title: 'TaskList.Status', center: false },
						{ title: 'TaskList.ScheduledTime', center: false },
						{ title: 'TaskList.FinishedTime', center: false },
						{ title: 'TaskList.ElapsedTime', center: false },
					],
					td: [],
				};
				getTasks(this.script_id).then((res) => {
					this.listDatas.td = [
						...this.listDatas.td,
						...res.rows.map((row) => {
							const schM = moment(row.scheduledTime);
							const endM = moment(row.time);
							const elapsed = endM.diff(schM) / 1000;
							const schT = schM.format('YYYY/MM/DD hh:mm:ss');
							const endT = endM.format('YYYY/MM/DD hh:mm:ss');
							const now = moment();
							const runSecs = now.diff(endM) / 1000;

							if (runSecs >= 86400 && row.status === 'running') {
								row.status = 'fail';
							}

							return {
								0: row.taskId,
								1: row.status,
								2: schT,
								3: endT,
								4: elapsed,
							};
						}),
					];
				});
			}
		},
	},
};
</script>
