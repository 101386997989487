import axios from 'axios';
import store from '@/store';
import { VueAxios } from './axios';

const request = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 6000,
});

const errorHandler = (error) => {
	// 這裏可以進行一些全局的錯誤判斷和處理，例如權限不足
	// 將 axios 的 error 解構，存取裡面的 data
	return Promise.reject(error.response.data);
};

// request interceptor
request.interceptors.request.use((config) => {
	const token = store.state.token;
	if (token) {
		config.headers['token'] = token;
	}
	return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
	return response.data;
}, errorHandler);

const installer = {
	vm: {},
	install(Vue) {
		Vue.use(VueAxios, request);
	},
};

export default request;

export { installer as VueAxios, request as axios };
