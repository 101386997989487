<template>
	<div class="account page">
		<h1 class="page-title">{{ $t('Subscribe.Title') }}</h1>
		<div class="form-block">
			<h3 class="sub-title" style="margin-top: 0">
				{{ $t('Subscribe.SelectPlan') }}
			</h3>
			<div class="form-col">
				<div class="form-group">
					<label for class="form-label">{{ $t('Subscribe.InputPlan') }}</label>
					<select name id class="form-control" v-model="planName">
						<option value="Basic">{{ $t('Plan.Basic') }}</option>
						<!-- <option value="Professional">{{ $t('Plan.Professional') }}</option>
						<option value="Corporate">{{ $t('Plan.Corporate') }}</option> -->
					</select>
				</div>
				<div class="form-group">
					<label for class="form-label">{{
						$t('Subscribe.InputPlanPeriod')
					}}</label>
					<select name id class="form-control" v-model="planPeriod">
						<option value="monthly">{{ $t('Plan.Monthly') }}</option>
						<option value="annually">{{ $t('Plan.Annually') }}</option>
					</select>
				</div>
			</div>
			<div class="accounting-list">
				<div class="accounting-item">
					<div>{{ planName }} / {{ planPeriodText }}</div>
					<div>${{ priceString }}</div>
				</div>
				<div class="accounting-item total">
					<div>Total</div>
					<div>${{ priceString }}</div>
				</div>
			</div>
			<div class="form-small-text">
				{{
					$t('Subscribe.PlanPaymentInfo', {
						price: priceString,
						date: todayText,
					})
				}}
			</div>
			<div class="button-group">
				<button class="button" @click="onSubmit">
					<span> {{ $t('Button.Next') }} </span>
				</button>
				<router-link :to="{ path: 'userAccount' }" class="button button-cancel">
					{{ $t('Button.Cancel') }}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
	components: {},
	name: 'Subscribe',
	data() {
		return {
			planName: 'Basic',
			planPeriod: 'monthly',
		};
	},
	computed: {
		...mapState(['token', 'plans', 'currentPlan']),
		...mapGetters(['pricePerMonthString']),
		todayText() {
			const today = new Date();
			return `${today.getDate()}/${
				today.getMonth() + 1
			} ${today.getFullYear()}`;
		},
		planPeriodText() {
			return this.planPeriod === 'monthly' ? '包月' : '包年';
		},
		priceString() {
			return this.plans[this.planName][this.planPeriod].toLocaleString();
		},
	},
	mounted() {},
	methods: {
		onSubmit() {
			this.$router.push({
				name: 'payment',
				params: {
					planName: this.planName,
					planPeriod: this.planPeriod,
					fromType: 'subscribe',
				},
			});
		},
	},
};
</script>

<style lang="less" scoped>
.tpfield {
	height: 40px;
	border: 1px solid transparent;
	margin: 5px 0;
	width: 100%;
	font-size: 15px;
	border-radius: 6px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	color: #000;
	background-color: #d6d4c9;
	padding: 0 15px;
	&::-webkit-input-placeholder {
		color: #999;
	}
	&:-ms-input-placeholder {
		color: #999;
	}
	&::placeholder {
		color: #999;
	}
}
input.form-control:not([type='checkbox']),
select.form-control,
textarea.form-control {
	display: block;
	height: 40px;
	&.form-large {
		height: 50px;
	}
}
.button-group {
	margin-top: 30px;
}
</style>
