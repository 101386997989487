<template>
	<div class="account page">
		<h1 class="page-title">{{ $t('UserAccount.ReplacementPlan') }}</h1>
		<div class="form-block">
			<div class="form-col">
				<div class="form-group">
					<label for="" class="form-label">{{
						$t('ReplacementPlan.CurrentPlan')
					}}</label>
					<select name="" id="" class="form-control" v-model="planName">
						<option value="Basic">{{ $t('Plan.Basic') }}</option>
						<!-- <option value="Professional">{{ $t('Plan.Professional') }}</option>
						<option value="Corporate">{{ $t('Plan.Corporate') }}</option> -->
					</select>
				</div>
				<div class="form-group">
					<label for="" class="form-label">{{
						$t('ReplacementPlan.Subscriptions')
					}}</label>
					<select name="" id="" class="form-control" v-model="planPeriod">
						<option value="monthly">{{ $t('Plan.Monthly') }}</option>
						<option value="annually">{{ $t('Plan.Annually') }}</option>
					</select>
				</div>
			</div>
			<div class="form-group">
				<a href="https://www.growise.io/Plans" target="_blank" class="has-i">
					<span>{{ $t('ReplacementPlan.AllPlan') }}</span>
					<i class="material-icons"> arrow_right_alt </i>
				</a>
			</div>
			<div class="form-group">
				<div class="plan-change-confirm">
					<h1 class="block-title">
						{{ $t('ReplacementPlan.ConfirmReplacement') }}
					</h1>
					<div class="plan-block old">
						<div>
							{{ $t('ReplacementPlan.CurrentPlan') }}：{{ currentPlan }} ${{
								pricePerMonthString(currentPlan, currentPlanPeriod)
							}}
							/
							{{ periodText(currentPlanPeriod) }}
						</div>
					</div>
					<div class="plan-block">
						<div>
							{{ $t('ReplacementPlan.NewPlan') }}：{{ planName }} ${{
								pricePerMonthString(planName, planPeriod)
							}}
							/
							{{ periodText(planPeriod) }}
						</div>
					</div>
					<i18n path="ReplacementPlan.NewPlanStartDate" tag="strong">
						<template v-slot:newDate>
							{{ nextPlanStartDate | dateFormat }}
						</template>
					</i18n>
				</div>
			</div>
			<div class="button-group">
				<button
					class="button"
					:class="{ 'button-disabled': loading }"
					@click="changePlan"
				>
					<span>
						{{ $t('Button.Next') }}
					</span>
				</button>
				<router-link
					:to="{ path: '/userAccount' }"
					class="button button-cancel"
				>
					{{ $t('UserInfo.Cancel') }}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
	name: 'ReplacementPlan',
	data() {
		return {
			planName: 'Basic',
			planPeriod: 'monthly',
			loading: false,
		};
	},
	computed: {
		...mapState([
			'token',
			'currentPlan',
			'currentPlanPeriod',
			'nextPlan',
			'nextPlanPeriod',
			'nextPaymentDate',
			'lastfour',
			'currentPlanExpiry',
		]),
		...mapGetters(['pricePerMonthString', 'nextPlanStartDate']),
	},
	mounted() {
		this.planName = this.currentPlan;
		this.planPeriod = this.currentPlanPeriod;
	},
	methods: {
		periodText(planPeriod) {
			return planPeriod === 'monthly' ? '包月' : '包年';
		},
		async changePlan() {
			this.$router.push({
				name: 'payment',
				params: {
					planName: this.planName,
					planPeriod: this.planPeriod,
					fromType: 'upgrade',
				},
			});
		},
	},
};
</script>

<style scoped></style>
