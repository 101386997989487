<template>
	<div class="login">
		<div class="login-overlay" />
		<div class="login-content">
			<div class="login-main">
				<div class="login-top">
					<img src="@/assets/images/logo.svg" alt="Growise" />
					<h1>Enter email to reset your password</h1>
				</div>
				<div class="form-group">
					<input
						type="text"
						class="form-control form-large"
						placeholder="Email"
						v-model="email"
					/>
				</div>
				<div class="form-group">
					<button
						class="button button-block button-large"
						type="button"
						@click="sendForgot"
					>
						Send
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { apiReset } from '@/api';

export default {
	name: 'Forgot',
	data() {
		return {
			email: '',
		};
	},
	methods: {
		async sendForgot() {
			let { email } = this;
			if (email == '') {
				alert('請填寫信箱');
				return;
			}

			try {
				this.response = await apiReset({ email: email });
				alert('密碼修改信件已寄送至您的信箱');
			} catch (error) {
				this.response = error;
				alert(error.message);
			}
		},
	},
};
</script>

<style scoped></style>
