<template>
	<div class="login">
		<div class="login-overlay" />
		<div class="login-content">
			<div class="login-main" style="min-width: 320px">
				<div class="login-top">
					<img src="@/assets/images/logo.svg" alt="Growise" />
					<h1>Update your password</h1>
				</div>
				<div class="form-group">
					<input
						type="password"
						class="form-control form-large"
						placeholder="Password"
						v-model="password"
					/>
				</div>
				<div class="form-group">
					<input
						type="password"
						class="form-control form-large"
						placeholder="Confirm Password"
						v-model="confirm_password"
					/>
				</div>
				<div class="form-group">
					<button
						class="button button-block button-large"
						type="button"
						@click="sendPassword"
					>
						Update
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { apiPassword } from '@/api';

export default {
	name: 'Password',
	data() {
		return {
			password: '',
			confirm_password: '',
		};
	},
	methods: {
		async sendPassword() {
			let { password, confirm_password } = this;
			if (password == '' || confirm_password == '') {
				alert('密碼不得為空');
				return;
			}
			if (password != confirm_password) {
				alert('密碼不一致');
				return;
			}
			const res = await apiPassword({
				t: this.$route.query.t,
				password: password,
			});
			if (res.success) {
				alert('密碼修改完成，請使用新密碼重新登入！');
				this.$router.push('/auth/login');
			} else {
				alert(res.message);
			}
		},
	},
};
</script>

<style scoped></style>
