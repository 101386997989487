<template>
	<div class="account page">
		<h1 class="page-title">{{ $t('UserAccount.ChargeDetails') }}</h1>
		<div class="form-group">
			{{ $t('ReplacementPlan.CurrentPlan') }}： {{ currentPlan }}/{{
				planPeriodText
			}}
		</div>
		<div class="form-group">
			{{ $t('ChargeDetails.NextPaymentDate') }}：{{
				nextPaymentDate | dateFormat
			}}
		</div>
		<div class="table-responsive">
			<table>
				<thead>
					<tr>
						<th>{{ $t('ChargeDetails.Date') }}</th>
						<th>{{ $t('ChargeDetails.PaymentContent') }}</th>
						<th>{{ $t('ChargeDetails.CardNumber') }}</th>
						<th>{{ $t('ChargeDetails.PaymentStatus') }}</th>
						<th>{{ $t('ChargeDetails.Total') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="log in paymentLogs" :key="log.id">
						<td>{{ log.date | dateFormat }}</td>
						<td>{{ log.planName }}/{{ log.planPeriod }}</td>
						<td>
							<span class="card-number"
								><img src="@/assets/images/payment/visa.svg" alt="visa" />••••
								•••• •••• {{ log.cardLastfour }}</span
							>
						</td>
						<td>Success</td>
						<td>${{ log.amount }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getChargeLogs } from '@/api';
export default {
	name: 'ChargeDetails',
	data() {
		return {
			paymentLogs: [],
		};
	},
	computed: {
		...mapState(['currentPlan', 'nextPaymentDate']),
		...mapGetters(['planPeriodText']),
	},
	mounted() {
		this.getLogs();
	},
	methods: {
		getLogs() {
			getChargeLogs().then((res) => {
				this.paymentLogs = res.data;
			});
		},
	},
};
</script>
