<template>
	<div class="login">
		<div class="login-overlay" />
		<div class="login-content">
			<div class="login-main">
				<div class="login-top">
					<img src="@/assets/images/logo.svg" alt="Growise" />
					<h1 v-if="active == null">
						帳戶驗證中
					</h1>
					<h1 v-else-if="active == true">
						您的帳戶已驗證成功！
					</h1>
					<h1 v-else>
						驗證發生錯誤。
					</h1>
				</div>
				<div class="form-group">
					<button
						class="button button-block button-large"
						type="button"
						@click="backToHome"
						v-if="active != null"
					>
						回首頁
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { apiActive } from '@/api';

export default {
	name: 'Active',
	data() {
		return {
			active: null,
		};
	},
	mounted() {
		this.sendActive();
	},
	methods: {
		backToHome() {
			this.$router.push('/');
		},

		async sendActive() {
			const that = this;
			const t = this.$route.query.t;
			if (t != '') {
				try {
					const res = await apiActive({ t: t });

					if (res.success) {
						that.active = true;
					} else {
						// that.active = false;
						that.$router.push('/');
					}
				} catch (error) {
					// that.active = false;
					that.$router.push('/');
				}
			} else {
				// that.active = false;
				that.$router.push('/');
			}
		},
	},
};
</script>

<style scoped></style>
