<template>
	<div class="account page">
		<h1 class="page-title">{{ $t('Chceckout.Title') }}</h1>
		<div class="form-block">
			<h3 class="sub-title" style="margin-top: 0">
				{{ $t('Chceckout.Header') }}
			</h3>
			<div class="form-small-text">
				{{ $t('Chceckout.Content') }}
			</div>
			<div class="button-group">
				<router-link
					:to="{ path: 'chargeDetails' }"
					class="button button-cancel"
				>
					{{ $t('Chceckout.ViewInvoice') }}
				</router-link>
				<router-link :to="{ path: 'userAccount' }" class="button">
					{{ $t('Chceckout.Continue') }}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	name: 'Checkout',
	data() {
		return {};
	},
	mounted() {
		this.$store.dispatch('FETCH_USER_INFO');
	},
	methods: {},
};
</script>

<style lang="less" scoped>
.tpfield {
	height: 40px;
	border: 1px solid transparent;
	margin: 5px 0;
	width: 100%;
	font-size: 15px;
	border-radius: 6px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	color: #000;
	background-color: #d6d4c9;
	padding: 0 15px;
	&::-webkit-input-placeholder {
		color: #999;
	}
	&:-ms-input-placeholder {
		color: #999;
	}
	&::placeholder {
		color: #999;
	}
}
input.form-control:not([type='checkbox']),
select.form-control,
textarea.form-control {
	display: block;
	height: 40px;
	&.form-large {
		height: 50px;
	}
}
.button-group {
	margin-top: 30px;
}
</style>
