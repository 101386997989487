import moment from 'moment-timezone';

export default {
    getAccountName(state) {
        return state.accountName;
    },
    getEmail(state) {
        return state.account;
    },
    accountName: (state) => {
        return state.accountName;
    },
    nextPlanStartDate(state) {
        const expiryDate = moment(state.currentPlanExpiry);
        // return expiryDate.add(1, 'days');
        return expiryDate;
    },
    pricePerMonthString: (state) => (planName, planPeriod) => {
        return state.plans[planName] && state.plans[planName][planPeriod] ? state.plans[planName][planPeriod].toLocaleString() : '';
    },
    planPeriodText(state) {
        return state.currentPlanPeriod === 'monthly' ? '包月' : '包年';
    }
}