<template>
	<div class="index page">
		<div class="data-card">
			<div class="card-item">
				<span class="material-icons card-icon">task</span>
				<div class="card-content">
					<div class="card-value">{{ dashboard.taskNum }}</div>
					<div class="card-title">{{ $t('Admin.ScriptNumber') }}</div>
				</div>
			</div>
			<div class="card-item">
				<span class="material-icons card-icon">storage</span>
				<div class="card-content">
					<div class="card-value">{{ dashboard.dataRows }}</div>
					<div class="card-title">{{ $t('Admin.DataNumber') }}</div>
				</div>
			</div>
			<div class="card-item">
				<span class="material-icons card-icon">assignment_turned_in</span>
				<div class="card-content">
					<div class="card-value">{{ successRate }} %</div>
					<div class="card-title">{{ $t('Admin.SuccessRate') }}</div>
				</div>
			</div>
			<div class="card-item">
				<span class="material-icons card-icon">timer</span>
				<div class="card-content">
					<div class="card-value">{{ avgElapsed }}</div>
					<div class="card-title">{{ $t('Admin.RunningTime') }}</div>
				</div>
			</div>
		</div>
		<h3 class="block-title">{{ $t('Admin.ScriptListTitle') }}</h3>
		<div class="table-responsive">
			<table>
				<thead>
					<tr>
						<th
							v-for="(data, key) in listDatas.th"
							:key="key"
							v-bind:class="{ 'text-center': data.center }"
						>{{ $t(data.title) }}</th>
					</tr>
				</thead>
				<tbody v-if="listDatas.td.length > 0">
					<tr v-for="(data, key) in listDatas.td" :key="key">
						<td>
							<router-link :to="{ name: 'Task', params: { script_id: data.id } }">{{ data.title }}</router-link>
						</td>
						<td>{{ data.createdTime }}</td>
						<td>{{ data.lastModified }}</td>
						<td>{{ data.lastScheduledAt }}</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<td class="text-center" colspan="7">No data available in table</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { getDashboard, getScripts } from '@/api';
export default {
	name: 'Index',
	data() {
		return {
			listDatas: {
				th: [
					{ title: 'Admin.ID', center: false },
					// { title: "Title", center: false },
					// { title: "Frequency", center: false },
					// { title: "Unit", center: false }
				],
				td: [],
			},
			dashboard: { taskNum: 0, successRate: 0, dataRows: 0, avgElapsed: 0 },
		};
	},
	computed: {
		token() {
			return this.$store.state.token;
		},
		successRate() {
			return (100 * this.dashboard.successRate).toFixed(2);
		},
		avgElapsed() {
			return this.dashboard.avgElapsed.toFixed(2);
		},
	},
	mounted() {
		this.getListDatas(this.currentTab);
		this.getDashboard();
	},
	methods: {
		getListDatas() {
			this.listDatas = {
				th: [
					{ title: 'Admin.ScriptName', center: false },
					{ title: 'Admin.CreatedTime', center: false },
					{ title: 'Admin.ModifiedTime', center: false },
					{ title: 'Admin.LastTime', center: false },
				],
				td: [],
			};
			getScripts({ withDeleted: 'true' }).then((res) => {
				this.listDatas.td = [
					...this.listDatas.td,
					...res.script_list.map((row) => {
						return {
							id: row.id,
							title: row.title,
							createdTime: row.createdAt,
							lastModified: row.updatedAt,
							lastScheduled: row.lastScheduledAt,
						};
					}),
				];
			});
		},
		getDashboard() {
			getDashboard().then((res) => {
				this.dashboard = res.result;
			});
		},
	},
};
</script>
