export default {
    setGroupID(state, val) {
        state.groupID = val;
    },
    setAccountID(state, val) {
        state.accountID = val;
    },
    setRecaptcha(state, val) {
        state.recaptcha = val;
    },
    setSchedule(state, val) {
        state.schedule = val;
    },
    setScriptNumber(state, val) {
        state.scriptNumber = val;
    },
    setToken(state, val) {
        state.token = val;
    },
    setPage(state, val) {
        state.page = val;
    },
    setScriptID(state, val) {
        state.scriptID = val;
    },
    setAccountName(state, val) {
        state.accountName = val;
    },
    setAccount(state, val) {
        state.account = val;
    },
    setCurrentPlan(state, val) {
        state.currentPlan = val;
    },
    setCurrentPlanPeriod(state, val) {
        state.currentPlanPeriod = val;
    },
    setNextPlan(state, val) {
        state.nextPlan = val;
    },
    setNextPlanPeriod(state, val) {
        state.nextPlanPeriod = val;
    },
    setNextPaymentDate(state, val) {
        state.nextPaymentDate = val;
    },
    setLastfour(state, val) {
        state.lastfour = val;
    },
    setCurrentPlanStartDate(state, val) {
        state.currentPlanStartDate = val;
    },
    setCreditTotal(state, val) {
        state.creditTotal = val;
    },
    setInvoice(state, val) {
        state.invoice = val;
    },
}