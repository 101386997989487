import Vue from 'vue';
import Vuex from 'vuex';

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		token: '',
		page: 'index',
		scriptID: '',
		recaptcha: false,
		schedule: false,
		scriptNumber: 0,
		groupID: '',
		account: '',
		accountID: '',
		accountName: '',
		currentPlan: '',
		currentPlanPeriod: '',
		nextPlan: '',
		nextPlanPeriod: '',
		nextPaymentDate: null,
		lastfour: '',
		currentPlanStartDate: null,
		plans: {
			Basic: {
				monthly: 3500,
				annually: 3000,
			},
			Professional: {
				monthly: 7500,
				annually: 6500,
			},
			Corporate: {
				monthly: 12000,
				annually: 9999,
			},
		},
		creditTotal: 0,
		invoice: {
			type: 'member',
			country_type: 'taiwan',
			barcode: '',
			cdcode: '',
			donate_code: '',
			donate_name: '',
			corporate_title: '',
			corporate_code: '',
			// taiwan or overseas
			address_country: 'taiwan',
			address_city: '',
			address_dist: '',
			address: '',
		},
		defaultInvoice: {
			type: 'member',
			country_type: 'taiwan',
			barcode: '',
			cdcode: '',
			donate_code: '',
			donate_name: '',
			corporate_title: '',
			corporate_code: '',
			// taiwan or overseas
			address_country: 'taiwan',
			address_city: '',
			address_dist: '',
			address: '',
		}
	},
	mutations,
	actions,
	getters,
});
