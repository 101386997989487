<template>
	<div class="header">
		<h1>
			<router-link to="/home">
				<img src="@/assets/images/logo.svg" alt="Growise" />
			</router-link>
			<span>Growise</span>
		</h1>
		<div class="header-tool-bar">
			<div>
				<button class="header-button button-icon" @click="toggleNavigation">
					<i class="material-icons" v-text="navIcon"></i>
				</button>
			</div>
			<div>
				<div
					class="form-select-control button-account"
					v-clickoutside="closeScriptSetting"
				>
					<button
						class="header-button select-handle"
						@click="scriptSettingToggle = !scriptSettingToggle"
					>
						<span class="user-name">{{
							account_name ? account_name : 'Hey!'
						}}</span>
						<i class="material-icons user-icon"> account_circle </i>
						<i class="material-icons"> expand_more </i>
					</button>
					<div
						class="select-list"
						v-bind:class="{ active: scriptSettingToggle }"
					>
						<div class="list-group">
							<router-link class="list-item" :to="{ path: '/userAccount' }">
								<i class="material-icons"> manage_accounts </i>
								<span class="list-item__text">{{ $t('User.Account') }}</span>
							</router-link>
							<div class="list-item" @click="doLogout">
								<i class="material-icons-outlined">logout</i>
								<span class="list-item__text">{{ $t('User.Logout') }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Header',
	props: {
		navActive: Boolean,
		navIcon: String,
	},
	data: function () {
		return {
			scriptSettingToggle: false,
		};
	},
	computed: {
		account_name() {
			return this.$store.getters.accountName;
		},
	},
	methods: {
		toggleNavigation: function () {
			this.$emit('toggleNavigation');
		},
		closeScriptSetting: function () {
			this.scriptSettingToggle = false;
		},
		doLogout() {
			this.$store.dispatch('Logout').then(() => {
				this.$router.push('/auth/login');
			});
		},
	},
	directives: {
		clickoutside: {
			bind: function (el, binding, vnode) {
				el.clickOutsideEvent = function (event) {
					if (!(el == event.target || el.contains(event.target))) {
						vnode.context[binding.expression](event);
					}
				};
				document.body.addEventListener('click', el.clickOutsideEvent);
				document.body.addEventListener('touchstart', el.clickOutsideEvent);
			},
			unbind: function (el) {
				document.body.removeEventListener('click', el.clickOutsideEvent);
				document.body.removeEventListener('touchstart', el.clickOutsideEvent);
			},
			stopProp(event) {
				event.stopPropagation();
			},
		},
	},
};
</script>

<style lang="less" scoped>
.list-item {
	&__text {
		word-break: keep-all;
	}
}
</style>
