<template>
	<div>
		<Header
			:navActive="navActive"
			:navIcon="navIcon"
			v-on:toggleNavigation="actionToggleNavigation"
		/>
		<main class="main">
			<Navigation
				:navActive="navActive"
				v-on:toggleNavigation="actionToggleNavigation"
			/>
			<div class="main-content">
				<Breadcrumb />
				<router-view />
			</div>
		</main>
	</div>
</template>
<script>
import Header from '@/components/Header';
import Navigation from '@/components/Navigation';
import Breadcrumb from '@/components/Breadcrumb';

export default {
	name: 'BasicLayout',
	components: { Header, Navigation, Breadcrumb },
	data() {
		return {
			navIcon: 'menu_open',
			navActive: false,
		};
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth >= 900) {
				this.navActive = true;
				document.body.classList.add('nav-open');
				this.navIcon = 'menu_open';
			} else {
				this.navActive = false;
				document.body.classList.remove('nav-open');
				this.navIcon = 'menu';
			}
		},
		actionToggleNavigation() {
			this.navActive = !this.navActive;
			if (this.navActive) {
				document.body.classList.add('nav-open');
				this.navIcon = 'menu_open';
			} else {
				document.body.classList.remove('nav-open');
				this.navIcon = 'menu';
			}
		},
	},
};
</script>