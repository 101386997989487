export function generateTableData(rows) {
	let th = [];
	let td = [];
	var headerSet = rows.reduce(
		(acc, row) => [...new Set([...acc, ...Object.keys(row)])],
		[]
	);
	// th = headerSet.map((th) => ({ title: th, center: false }));
	th = [
		{ title: 'Log.Date', center: false },
		{ title: 'Log.Time', center: false },
		{ title: 'Log.Action', center: false },
		{ title: 'Log.Status', center: false },
		{ title: 'Log.ErrorMessage', center: false },
	];
	rows.forEach((element) => {
		let tmp_td = new Array(headerSet.length).fill('', 0);
		Object.entries(element).forEach(([key, value]) => {
			return (tmp_td[headerSet.indexOf(key)] = value);
		});
		td.push(tmp_td);
	});
	return { th, td };
}
export function genResult(rows) {
	let th = [];
	let td = [];
	var headerSet = rows.reduce(
		(acc, row) => [...new Set([...acc, ...Object.keys(row)])],
		[]
	);
	headerSet = headerSet.filter((header) => header !== '_id');
	th = headerSet.map((th) => ({ title: th, center: false }));
	rows.forEach((element) => {
		delete element._id;

		let tmp_td = new Array(headerSet.length).fill('', 0);

		Object.entries(element).forEach(
			([key, value]) => (tmp_td[headerSet.indexOf(key)] = value)
		);
		td.push(tmp_td);
	});
	return { th, td };
}
