<template>
	<div class="account page">
		<h1 class="page-title">{{ $t('UpdatePayment.UpdateCreditCard') }}</h1>
		<div class="form-block">
			<h2 class="sub-title">{{ $t('UpdatePayment.CreditOrDebit') }}</h2>
			<div class="form-group logo-list">
				<img src="@/assets/images/payment/visa.svg" alt="visa" />
				<img src="@/assets/images/payment/mastercard.svg" alt="mastercard" />
				<img src="@/assets/images/payment/jcb.svg" alt="jcb" />
				<img src="@/assets/images/payment/amex.svg" alt="amex" />
			</div>
			<div class="form-group">
				<label for class="form-label">{{ $t('UpdatePayment.CreditCardNumber') }}</label>
				<div type="text" id="number" class="form-control tpfield" ref="number"></div>
			</div>
			<div class="form-col">
				<div class="form-group">
					<label for class="form-label">{{ $t('UpdatePayment.ExpiryDate') }}</label>
					<div class="form-control tpfield" id="expirationDate" ref="expirationDate"></div>
				</div>
				<div class="form-group">
					<label for class="form-label">
						{{ $t('UpdatePayment.SecurityCode') }}
						<i
							class="material-icons"
							v-tooltip="
								'Your card security code (CVV) is a set of 3 or 4 digit numbers, usually located on the back of the card.'
							"
						>help_outline</i>
					</label>
					<div class="form-control tpfield" id="ccv" ref="ccv"></div>
				</div>
			</div>
			<ReceiptSection v-model="receiptData" />
			<div class="button-group">
				<button class="button" @click="onSubmit">{{ $t('UserInfo.Save') }}</button>
				<router-link
					:to="{ path: 'userAccount' }"
					class="button button-cancel"
				>{{ $t('UserInfo.Cancel') }}</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { changeUserCard } from '@/api';
import ReceiptSection from '@/components/ReceiptSection';
export default {
	components: {
		ReceiptSection,
	},
	name: 'ChangeCredit',
	data() {
		return {
			receiptData: {},
		};
	},
	computed: {
		invoice() {
			return this.$store.state.invoice;
		},
	},
	watch: {
		invoice(val) {
			this.receiptData = Object.assign({}, val);
		}
	},
	mounted() {
		this.initTappay();
	},
	methods: {
		initTappay() {
			TPDirect.setupSDK(
				process.env.VUE_APP_TAPPAY_ID,
				process.env.VUE_APP_TAPPAY_KEY,
				'sandbox'
			);

			const fields = {
				number: {
					element: '#number',
					placeholder: '**** **** **** ****',
				},
				expirationDate: {
					element: '#expirationDate',
					placeholder: 'MM/YY',
				},
				ccv: {
					element: '#ccv',
					placeholder: '後三碼',
				},
				remember: true,
			};
			TPDirect.card.setup({
				fields: fields,
				styles: {
					// style focus state
					':focus': {
						// 'color': 'black'
					},
					// style valid state
					'.valid': {
						color: 'green',
					},
					// style invalid state
					'.invalid': {
						color: 'red',
					},
					// Media queries
					// Note that these apply to the iframe, not the root window.
					'@media screen and (max-width: 400px)': {
						input: {
							color: '#000000',
						},
					},
				},
			});

			TPDirect.card.onUpdate((update) => {
				console.log('update');
				if (update.canGetPrime) {
					//全部欄位皆為正確 可以呼叫 getPrime
					this.disabledBtnPay = false;
				} else {
					this.disabledBtnPay = false;
				}

				this.updateStatus(update.status.number);
				this.updateStatus(update.status.expiry);
				this.updateStatus(update.status.number);
			});
		},
		updateStatus(field) {
			switch (field) {
				case 0:
					//欄位已填好，並且沒有問題
					console.log('field is ok');
					break;
				case 1:
					//欄位還沒有填寫
					console.log('field is empty');
					break;
				case 2:
					//欄位有錯誤，此時在 CardView 裡面會用顯示 errorColor
					console.log('field has error');
					break;
				case 3:
					//使用者正在輸入中
					console.log('usertyping');
					break;
				default:
					console.log('error!');
			}
		},
		onSubmit() {
			const tappayStatus = TPDirect.card.getTappayFieldsStatus();
			if (tappayStatus.canGetPrime === false) {
				// can not get prime
				console.log('can not get prime');
				return;
			}

			// Get prime
			TPDirect.card.getPrime((result) => {
				if (result.status !== 0) {
					// get prime error
					console.log(result.msg);
					return;
				}

				let { prime, lastfour } = result.card;
				this.changeCard(prime, lastfour);
			});
		},
		async changeCard(prime, lastfour) {
			const payload = {
				prime,
				lastfour,
				invoice: this.receiptData,
			};
			await changeUserCard(payload);
			await this.$store.dispatch('FETCH_USER_INFO');
			this.$router.push({ path: 'userAccount' });
		},
	},
};
</script>

<style lang="less" scoped>
.tpfield {
	height: 40px;
	border: 1px solid transparent;
	margin: 5px 0;
	width: 100%;
	font-size: 15px;
	border-radius: 6px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	color: #000;
	background-color: #d6d4c9;
	padding: 0 15px;
	&::-webkit-input-placeholder {
		color: #999;
	}
	&:-ms-input-placeholder {
		color: #999;
	}
	&::placeholder {
		color: #999;
	}
}
input.form-control:not([type="checkbox"]),
select.form-control,
textarea.form-control {
	display: block;
	height: 40px;
	&.form-large {
		height: 50px;
	}
}
</style>
