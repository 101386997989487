import Vue from 'vue';
import App from './App.vue';
import '@/assets/less/style.less';
import router from './router';
import store from './store';
import i18n from './plugins/vue-i18n';

import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import VSwitch from 'v-switch-case';

import './filters';
import './route-process';

// Bootstrap Vue
import '@/plugins/bootstrap-vue';

Vue.use(Tooltip);

Vue.use(VSwitch);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
