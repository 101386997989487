<template>
	<div class="account page">
		<h1 class="page-title">{{ $t('Payment.Title') }}</h1>
		<div class="form-block">
			<div class="col-group">
				<h3 class="sub-title">{{ $t('Payment.PaymentMethod') }}</h3>
				<div class="form-group logo-list">
					<img src="@/assets/images/payment/visa.svg" alt="visa" />
					<img src="@/assets/images/payment/mastercard.svg" alt="mastercard" />
					<img src="@/assets/images/payment/jcb.svg" alt="jcb" />
					<img src="@/assets/images/payment/amex.svg" alt="amex" />
				</div>
				<!-- Logo List -->
				<div class="form-group">
					<label for class="form-label">{{
						$t('Subscribe.InputCardNumber')
					}}</label>
					<div
						type="text"
						id="number"
						class="form-control tpfield"
						ref="number"
					></div>
				</div>
				<div class="form-col">
					<div class="form-group">
						<label for class="form-label">{{
							$t('Subscribe.InputExpiryDate')
						}}</label>
						<div
							class="form-control tpfield"
							id="expirationDate"
							ref="expirationDate"
						></div>
					</div>
					<div class="form-group">
						<label for class="form-label">
							{{ $t('Subscribe.InputCVV') }}
							<i
								class="material-icons"
								v-tooltip="
									'Your card security code (CVV) is a set of 3 or 4 digit numbers, usually located on the back of the card.'
								"
								>help_outline</i
							>
						</label>
						<div class="form-control tpfield" id="ccv" ref="ccv"></div>
					</div>
				</div>
			</div>
			<ReceiptSection v-model="receiptData" />
			<div class="button-group">
				<button
					class="button"
					:class="{ 'button-disabled': loading }"
					@click="onSubmit"
				>
					<span v-if="loading"> {{ $t('Button.Pending') }} </span>
					<span v-else> {{ $t('Button.Submit') }} </span>
				</button>
				<router-link :to="{ path: 'userAccount' }" class="button button-cancel">
					{{ $t('Button.Cancel') }}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { apiPrimeWith3D, apiOrder } from '@/api';
import ReceiptSection from '@/components/ReceiptSection.vue';
export default {
	components: {
		ReceiptSection,
	},
	name: 'Payment',
	data() {
		return {
			planName: 'Basic',
			planPeriod: 'monthly',
			receiptData: {},
			loading: false,
		};
	},
	computed: {
		...mapState(['token', 'plans', 'invoice', 'currentPlan', 'defaultInvoice']),
		...mapGetters(['pricePerMonthString']),
		todayText() {
			const today = new Date();
			return `${today.getDate()}/${
				today.getMonth() + 1
			} ${today.getFullYear()}`;
		},
		planPeriodText() {
			return this.planPeriod === 'monthly' ? '包月' : '包年';
		},
		priceString() {
			return this.plans[this.planName][this.planPeriod].toLocaleString();
		},
	},
	watch: {
		invoice(val) {
			this.receiptData = Object.assign({}, val);
		},
	},
	mounted() {
		const _planName = this.$route.params.planName;
		const _planPeriod = this.$route.params.planPeriod;
		if (_planName && _planPeriod) {
			this.planName = _planName;
			this.planPeriod = _planPeriod;
		} else {
			this.$router.push('/');
		}

		TPDirect.setupSDK(
			process.env.VUE_APP_TAPPAY_ID,
			process.env.VUE_APP_TAPPAY_KEY,
			'sandbox'
		);

		const fields = {
			number: {
				element: '#number',
				placeholder: '**** **** **** ****',
			},
			expirationDate: {
				element: '#expirationDate',
				placeholder: 'MM/YY',
			},
			ccv: {
				element: '#ccv',
				placeholder: '後三碼',
			},
			remember: true,
		};
		TPDirect.card.setup({
			fields: fields,
			styles: {
				// style focus state
				':focus': {
					// 'color': 'black'
				},
				// style valid state
				'.valid': {
					color: 'green',
				},
				// style invalid state
				'.invalid': {
					color: 'red',
				},
				// Media queries
				// Note that these apply to the iframe, not the root window.
				'@media screen and (max-width: 400px)': {
					input: {
						color: '#000000',
					},
				},
			},
		});

		TPDirect.card.onUpdate((update) => {
			if (update.canGetPrime) {
				//全部欄位皆為正確 可以呼叫 getPrime
				this.disabledBtnPay = false;
			} else {
				this.disabledBtnPay = false;
			}

			this.updateStatus(update.status.number);
			this.updateStatus(update.status.expiry);
			this.updateStatus(update.status.number);
		});
		this.receiptData = Object.assign(
			{},
			this.invoice != null ? this.invoice : this.defaultInvoice
		);
	},
	methods: {
		updateStatus(field) {
			switch (field) {
				case 0:
					//欄位已填好，並且沒有問題
					// console.log('field is ok');
					break;
				case 1:
					//欄位還沒有填寫
					// console.log('field is empty');
					break;
				case 2:
					//欄位有錯誤，此時在 CardView 裡面會用顯示 errorColor
					// console.log('field has error');
					break;
				case 3:
					//使用者正在輸入中
					// console.log('usertyping');
					break;
				default:
				// console.log('error!');
			}
		},
		async onSubmit() {
			this.loading = true;
			const payload = {
				planName: this.planName,
				planPeriod: this.planPeriod,
				invoice: this.receiptData,
			};
			const res = await apiOrder(payload);
			if (res['orderId'] && res['price'] && res['price'] > 0) {
				this.processTappay(res['orderId']);
			} else {
				this.loading = false;
				alert(
					'此方案目前無法購買，請稍後再嘗試，如重複性出現此問題請通知客服。'
				);
			}
		},
		processTappay(orderId) {
			const that = this;
			const tappayStatus = TPDirect.card.getTappayFieldsStatus();
			if (tappayStatus.canGetPrime === false) {
				// can not get prime
				// console.log('can not get prime');
				alert(that.$t('Subscribe.AlertCardTappayFail'));
				that.loading = false;
				return;
			}

			// Get prime
			TPDirect.card.getPrime((result) => {
				if (result.status !== 0) {
					// get prime error
					// console.log(result.msg);
					alert(result.msg);
					that.loading = false;
					return;
				}

				let { prime, lastfour } = result.card;
				this.submitPrime(prime, lastfour, orderId);
			});
		},
		async submitPrime(prime, lastfour, orderId) {
			const payload = {
				prime,
				lastfour,
				planName: this.planName,
				planPeriod: this.planPeriod,
				invoice: this.receiptData,
				orderId,
			};
			const res = await apiPrimeWith3D(payload);
			this.loading = false;
			if (res.success && res.payment_url) {
				window.location.href = res.payment_url;
				// await this.$store.dispatch('FETCH_USER_INFO');
				// this.$router.push({ path: 'userAccount' });
			} else {
				alert(res.message);
			}
		},
	},
};
</script>

<style lang="less" scoped>
.tpfield {
	height: 40px;
	border: 1px solid transparent;
	margin: 5px 0;
	width: 100%;
	font-size: 15px;
	border-radius: 6px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	color: #000;
	background-color: #d6d4c9;
	padding: 0 15px;
	&::-webkit-input-placeholder {
		color: #999;
	}
	&:-ms-input-placeholder {
		color: #999;
	}
	&::placeholder {
		color: #999;
	}
}
input.form-control:not([type='checkbox']),
select.form-control,
textarea.form-control {
	display: block;
	height: 40px;
	&.form-large {
		height: 50px;
	}
}
</style>
