import request from '@/utils/request'

export function login(userData) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: userData
  })
}

export function getUserInfo() {
  return request({
    url: '/user',
    method: 'get'
  })
}

export function getDashboard() {
  return request({
    url: '/dashboard',
    method: 'get'
  })
}

export function getScripts(parameter) {
  return request({
    url: '/script',
    method: 'get',
    params: parameter
  })
}

export function getScript(scriptId) {
  return request({
    url: `/script/${scriptId}`,
    method: 'get'
  })
}

export function getCSV(parameter) {
  return request({
    url: '/download_csv',
    method: 'get',
    responseType: 'blob',
    params: { ...parameter, type: 'csv' }
  })
}

export function getTasks(scriptId) {
  return request({
    url: '/tasks',
    method: 'get',
    params: { script_id: scriptId }
  })
}

export function getTask(taskId) {
  return request({
    url: `/tasks/${taskId}`,
    method: 'get'
  })
}

export function getScriptResults(scriptId) {
  return request({
    url: `/results/${scriptId}`,
    method: 'get'
  })
}

export function getTaskResults(scriptId, taskId) {
  return request({
    url: `/results/${scriptId}/${taskId}`,
    method: 'get'
  })
}

export function getLogs(taskId) {
  return request({
    url: `/logs/${taskId}`,
    method: 'get'
  })
}

export function getUsedCredit() {
  return request({
    url: `/credit/count`,
    method: 'get'
  })
}

export function getCreditDetail() {
  return request({
    url: `/credit`,
    method: 'get'
  })
}

export function getChargeLogs() {
  return request({
    url: `/payment/logs`,
    method: 'get'
  })
}

export function changeUserPlan(data) {
  return request({
    url: `/payment/subscribe`,
    method: 'patch',
    data
  })
}

export function changeUserCard(data) {
  return request({
    url: `/payment/card`,
    method: 'post',
    data
  })
}

export function subscribe(data) {
  return request({
    url: `/payment/subscribe`,
    method: 'post',
    data
  })
}

// 忘記密碼＆重設密碼
export function apiReset(data) {
  return request({
    url: `/user/reset`,
    method: 'post',
    data
  })
}

// 驗證信
export function apiVerify(data) {
  return request({
    url: `/auth/verify`,
    method: 'post',
    data
  })
}

// 驗證信箱
export function apiActive(data) {
  return request({
    url: `/auth/active`,
    method: 'post',
    data
  })
}

// 修改密碼
export function apiPassword(data) {
  return request({
    url: `/user/password`,
    method: 'post',
    data
  })
}

// 升級方案
export function apiUpgrade(data) {
  return request({
    url: `/subscription/upgrade`,
    method: 'post',
    data
  })
}

// 取消訂閱
export function apiCancel(data) {
  return request({
    url: `/subscription/cancel`,
    method: 'post',
    data
  })
}

// 發票
export function apiInvoice(data) {
  return request({
    url: `/payment/invoice`,
    method: 'post',
    data
  })
}

// 新訂單
export function apiOrder(data) {
  return request({
    url: `/payment/order`,
    method: 'post',
    data
  })
}

// Prime with 3D
export function apiPrimeWith3D(data) {
  return request({
    url: `/subscription/prime`,
    method: 'post',
    data
  })
}