<template>
	<div class="account page">
		<h1 class="page-title">{{ $t('UserAccount.ChangeEmail') }}</h1>
		<div class="form-block">
			<div class="form-group">
				<input type="email" class="form-control" v-model="email" />
			</div>
			<div class="button-group">
				<button class="button">{{ $t('UserInfo.Save') }}</button>
				<router-link
					:to="{ path: '/userAccount' }"
					class="button button-cancel"
				>
					{{ $t('UserInfo.Cancel') }}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ChangeEmail',
	data() {
		return {
			email: '',
		};
	},
	mounted() {
		this.email = this.$store.state.account;
	},
};
</script>
