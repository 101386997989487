<template>
	<div class="breadcrumb">
		<router-link :to="{ path: '/home' }">
			{{ $t('Breadcrumb.Home') }}
		</router-link>
		<i v-if="showChevronIcon()" class="material-icons">chevron_right</i>
		<span> {{ fetchBreadcrumb() }}</span>
	</div>
</template>

<script>
export default {
	name: 'Breadcrumb',
	props: {},
	mounted() {},
	methods: {
		showChevronIcon() {
			const menu = this.$route.meta.menu;
			if (menu == 'Home') {
				return false;
			}
			return true;
		},
		fetchBreadcrumb() {
			const menu = this.$route.meta.menu;
			switch (menu) {
				case 'Subscribe':
					return this.$i18n.t('Breadcrumb.Subscribe');
				case 'ReplacementPlan':
					return this.$i18n.t('Breadcrumb.ReplacementPlan');
				case 'ChargeDetails':
					return this.$i18n.t('Breadcrumb.ChargeDetails');
				case 'ChangeCredit':
					return this.$i18n.t('Breadcrumb.ChangeCredit');
				case 'QuotaDetails':
					return this.$i18n.t('Breadcrumb.QuotaDetails');
				case 'UserAccount':
					return this.$i18n.t('Breadcrumb.UserAccount');
				case 'ChangeEmail':
					return this.$i18n.t('Breadcrumb.ChangeEmail');
				case 'ChangeName':
					return this.$i18n.t('Breadcrumb.ChangeName');
				case 'Payment':
					return this.$i18n.t('Breadcrumb.Payment');
				case 'Log':
					return this.$i18n.t('Breadcrumb.Log');
				case 'Task':
					return this.$i18n.t('Breadcrumb.Task');
				default:
					return '';
			}
		},
	},
};
</script>

<style scoped></style>
