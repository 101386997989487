<template>
	<div class="index page">
		<h1 class="page-title">
			<router-link
				class="page-title"
				:to="{
					name: 'Task',
					params: { script_id },
				}"
			>
				{{ script_title }}
			</router-link>
			<span class="page-title"> > {{ task_id }}</span>
		</h1>
		<!-- <h1 class="page-title">> {{task_id}}</h1> -->
		<ul class="tab">
			<li
				v-bind:class="{ active: currentTab == 'Overview' }"
				@click="changeTab('Overview')"
			>
				{{ $t('TaskList.Overview') }}
			</li>
			<li
				v-bind:class="{ active: currentTab == 'Results' }"
				@click="changeTab('Results')"
			>
				{{ $t('TaskList.Results') }}
			</li>
			<li
				v-bind:class="{ active: currentTab == 'Log' }"
				@click="changeTab('Log')"
			>
				{{ $t('TaskList.Log') }}
			</li>
			<!-- <li>
							<a
								:href="
									`${process.env.VUE_APP_API_URL}/snapshop_task/task_id=${task_id}`
								"
								target="_blank"
								>Snapshot</a
							>
						</li> -->
		</ul>
		<div
			class="tool-bar"
			v-if="currentTab == 'Results' && listDatas.td.length > 0"
		>
			<div class="left"></div>
			<div class="right">
				<a
					class="button button-small"
					href="javascript:void(0)"
					@click="downloadCSV"
				>
					<i class="material-icons-outlined">file_download</i> CSV
				</a>
			</div>
		</div>

		<div class="table-responsive">
			<table>
				<thead>
					<tr>
						<th
							v-for="(data, key) in listDatas.th"
							:key="key"
							v-bind:class="{ 'text-center': data.center }"
						>
							{{ $t(data.title) }}
						</th>
					</tr>
				</thead>
				<tbody v-if="listDatas.td.length > 0">
					<tr v-for="(datas, key) in listDatas.td" :key="key">
						<td
							v-for="(data, key2) in datas"
							:key="key2"
							v-bind:class="{ 'text-center': listDatas.th[key2].center }"
						>
							<span
								v-if="key2 == 2 && listDatas.th[key2].title === 'Log.Action'"
							>
								{{ $t(`Log.${data}`) }}
							</span>
							<span v-else> {{ data }} </span>
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<td class="text-center" colspan="7">No data available in table</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { getCSV, getScript, getTask, getTaskResults, getLogs } from '@/api';
import { genResult, generateTableData } from '@/utils/util';
export default {
	name: 'Log',
	props: ['task_id', 'script_id'],
	data() {
		return {
			currentTab: 'Overview',
			listDatas: { th: [], td: [] },
			script_title: '',
		};
	},
	watch: {
		currentTab(val) {
			this.getListDatas(val);
		},
	},
	mounted() {
		this.getScriptData();
		this.getListDatas(this.currentTab);
	},
	methods: {
		downloadCSV() {
			getCSV({ script_id: this.script_id, task_id: this.task_id }).then(
				(response) => {
					const url = window.URL.createObjectURL(new Blob([response]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'file.csv');
					document.body.appendChild(link);
					link.click();
				}
			);
		},
		changeTab(type) {
			this.currentTab = type;
		},
		getScriptData() {
			getScript(this.script_id).then((res) => {
				this.script_title = res.title;
			});
		},
		getListDatas(type) {
			this.listDatas = { th: [], td: [] };

			if (type == 'Overview') {
				this.listDatas.th = [
					{ title: 'TaskList.TaskID', center: false },
					{ title: 'TaskList.Status', center: false },
					{ title: 'TaskList.ScheduledTime', center: false },
					{ title: 'TaskList.FinishedTime', center: false },
					{ title: 'TaskList.ElapsedTime', center: false },
				];
				getTask(this.task_id).then((res) => {
					const row = res;
					const schM = moment(row.scheduledTime);
					const endM = moment(row.time);
					const elapsed = endM.diff(schM) / 1000;
					const schT = schM.format('YYYY/MM/DD hh:mm:ss');
					const endT = endM.format('YYYY/MM/DD hh:mm:ss');
					this.listDatas.td.push({
						0: row.taskId,
						1: row.status,
						2: schT,
						3: endT,
						4: elapsed,
					});
				});
			} else if (type == 'Results') {
				getTaskResults(this.script_id, this.task_id).then((res) => {
					this.listDatas = genResult(res.rows);
				});
			} else {
				getLogs(this.task_id).then((res) => {
					this.listDatas = generateTableData(res.logs);
				});
			}
		},
	},
};
</script>
