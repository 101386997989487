<template>
	<div class="account page">
		<h1 class="page-title">{{ $t('UserAccount.UsageDetails') }}</h1>
		<div class="quotas-box">
			<h4 class="current-quotas">
				<strong>{{ creditUsed }}</strong>
				of {{ creditTotal }}
			</h4>
			<div class="quotas-desc">{{ creditPercent }}% of total storage used.</div>
			<div class="quotas-bar">
				<span v-bind:style="{ width: creditPercent + '%' }" />
			</div>
		</div>
		<div class="table-responsive">
			<table>
				<thead>
					<tr>
						<th>Script Title</th>
						<th>Issued Date</th>
						<th>Credit Used</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="task in tasks" :key="task.task_id">
						<td>{{ task.scriptTitle }}</td>
						<td>{{ task.issueTime }}</td>
						<td>{{ task.creditUsed }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { getCreditDetail, getUsedCredit } from '@/api';
export default {
	name: 'QuotaDetails',
	data() {
		return {
			tasks: [],
			creditUsed: 0,
		};
	},
	computed: {
		creditPercent() {
			return this.creditTotal !== null
				? Math.round((this.creditUsed / this.creditTotal) * 10000) / 100
				: 0;
		},
		...mapState(['creditTotal']),
	},
	mounted() {
		this.getListDatas();
		this.getCreditUsed();
	},
	methods: {
		getListDatas() {
			getCreditDetail().then((res) => {
				this.tasks = res.result;
			});
		},
		getCreditUsed() {
			getUsedCredit().then((res) => {
				this.creditUsed = res.result.total_used;
			});
		},
	},
};
</script>
