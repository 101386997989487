import Vue from 'vue';
import VueRouter from 'vue-router';
import { AuthLayout, BasicLayout } from '@/layouts';
import NotFound from '@/pages/exception/404';
import Login from '@/pages/user/Login.vue';
import Forgot from '@/pages/user/Forgot.vue';
import Verify from '@/pages/user/Verify.vue';
import Active from '@/pages/user/Active.vue';
import Password from '@/pages/user/Password.vue';
import Index from '@/pages/crawler/Index';
import Task from '@/pages/crawler/Task';
import Log from '@/pages/crawler/Log';
import UserAccount from '@/pages/account/Index';
import QuotaDetails from '@/pages/account/QuotaDetails';
import ReplacementPlan from '@/pages/account/ReplacementPlan';
import ChargeDetails from '@/pages/account/ChargeDetails';
import Subscribe from '@/pages/account/Subscribe';
import ChangeCredit from '@/pages/account/ChangeCredit';
import ChangeEmail from '@/pages/account/ChangeEmail';
import ChangeName from '@/pages/account/ChangeName';
import Payment from '@/pages/account/Payment';
import Checkout from '@/pages/account/Checkout';
Vue.use(VueRouter);

export default new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home',
      component: BasicLayout,
      children: [
        {
          path: '/home',
          name: 'Home',
          component: Index,
          meta: { title: 'Growise', auth: true, menu: 'Home' },
        },
        {
          path: '/scripts/:script_id([1-9]\\d*)/tasks',
          name: 'Task',
          component: Task,
          props: true,
          meta: { title: 'Growise', auth: true, menu: 'Task' },
        },
        {
          path: '/scripts/:script_id([1-9]\\d*)/tasks/:task_id([1-9]\\d*)/logs',
          name: 'Log',
          component: Log,
          props: true,
          meta: { title: 'Growise', auth: true, menu: 'Log' },
        },
        {
          path: '/userAccount',
          name: 'userAccount',
          component: UserAccount,
          meta: { title: 'Account', auth: true, menu: 'UserAccount' },
        },
        {
          path: '/changeEmail',
          name: 'changeEmail',
          component: ChangeEmail,
          meta: { title: 'Change Email', auth: true, menu: 'ChangeEmail' },
        },
        {
          path: '/changeName',
          name: 'changeName',
          component: ChangeName,
          meta: { title: 'Change Name', auth: true, menu: 'ChangeName' },
        },
        {
          path: '/quotaDetails',
          name: 'quotaDetails',
          component: QuotaDetails,
          meta: { title: 'See quota Details', auth: true, menu: 'QuotaDetails' },
        },
        {
          path: '/replacementPlan',
          name: 'replacementPlan',
          component: ReplacementPlan,
          meta: { title: 'Change Subscription Plan', auth: true, menu: 'ReplacementPlan' },
        },
        {
          path: '/chargeDetails',
          name: 'chargeDetails',
          component: ChargeDetails,
          meta: { title: 'Charge Details', auth: true, menu: 'ChargeDetails' },
        },
        {
          path: '/subscribe',
          name: 'subscribe',
          component: Subscribe,
          meta: { title: 'Subscribe', auth: true, menu: 'Subscribe' },
        },
        {
          path: '/changeCredit',
          name: 'changeCredit',
          component: ChangeCredit,
          meta: { title: 'Change Credit', auth: true, menu: 'ChangeCredit' },
        },
        {
          path: '/payment',
          name: 'payment',
          component: Payment,
          meta: { title: 'Payment', auth: true, menu: 'Payment' },
        },
        {
          path: '/checkout',
          name: 'checkout',
          component: Checkout,
          meta: { title: 'Checkout', auth: true, menu: 'Checkout' },
        },
      ]
    },
    {
      path: '/auth',
      component: AuthLayout,
      redirect: '/auth/login',
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login,
          meta: { title: 'Growise' },
        },
        {
          path: 'forgot',
          name: 'Forgot',
          component: Forgot,
          meta: { title: 'Forgot', },
        },
        {
          path: 'reset',
          name: 'Reset',
          component: Forgot,
          meta: { title: 'Reset', },
        },
        {
          path: 'verify',
          name: 'Verify',
          component: Verify,
          meta: { title: 'Verify', },
        },
        {
          path: 'active',
          name: 'Active',
          component: Active,
          meta: { title: 'Active', },
        },
        {
          path: 'password',
          name: 'Password',
          component: Password,
          meta: { title: 'Password', },
        }
      ]
    },
    {
      path: '*',
      component: NotFound
    }
  ],
});
